import React from 'react';
import PropTypes from "prop-types";
import { Input, Button, Modal, Form, InputNumber, Checkbox, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { isUndefined } from '../../utils/JsObjectHelper';
import FormColorPicker from './FormColorPicker';

const { Option } = Select;

class FormNameValueManipulationModal extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            addModalVisible: false,
            initVals: { name: "", value: null },
            valueType: "string"
        };
    }

    /*componentDidMount() {
        // To disable submit button at the beginning.
        //this.props.form.validateFields();
    }*/

    /**
     * React lifecycle after update method
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOpen && this.props.isOpen) {
            let vylType = "string";
            if (typeof this.props.itemValues.value === 'boolean' || typeof this.props.itemValues.value === 'number') {
                vylType = typeof this.props.itemValues.value;
            }
            this.setState({ addModalVisible: true, initVals: this.props.itemValues, valueType: vylType }, () => {
                if (!isUndefined(this.formRef.current)) {
                    this.formRef.current.resetFields();
                }
            });
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        this.formRef.current.validateFields()
            .then(values => {
                if (this.props.isEdit) {
                    this.props.onItemUpdated(values.name, values.value, values.color, this.props.itemValues);
                } else {
                    this.props.onItemAdd(values.name, values.value, values.color);
                }
                this.hideModal();
            })
            .catch(errorInfo => {
                console.log(errorInfo);
                return;
            });
    };

    hideModal = () => {
        this.setState({ addModalVisible: false, initVals: { name: "", value: null }, valueType: "string" }, () => {
            this.formRef.current.resetFields();
            this.props.onModalHide();
        });
    };

    openModalNew = () => {
        this.setState({ addModalVisible: true, initVals: { name: "", value: null }, valueType: "string" }, () => {
            this.formRef.current.resetFields();
        })
    };

    handleValueTypeChange = (e) => {
        this.setState({ valueType: e });
    }

    render() {
        const { t } = this.props;
        return [
            <Button key="addButton" size="small" icon={<PlusOutlined />} onClick={this.openModalNew}>
                {t('app.genericControls.formNameValueManipulationModal.btnAddItem')}
            </Button>,
            <Modal
                title={t('app.genericControls.formNameValueManipulationModal.titleAddItem')}
                key="addItemModal"
                onCancel={this.hideModal}
                okButtonProps={{ hidden: true }}
                visible={this.state.addModalVisible}>

                <Form layout="vertical"
                    name="horizontal_addUrl"
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                    initialValues={this.state.initVals}
                >
                    <Form.Item label={t('app.genericControls.formNameValueManipulationModal.lblAddName')}
                        name="name"
                        rules={[{ required: true, message: t('app.genericControls.formNameValueManipulationModal.msgAddName') }]}>
                        <Input
                            placeholder={t('app.genericControls.formNameValueManipulationModal.lblAddName')}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            if (this.props.useTypes) {
                                return (
                                    <Form.Item label={t('app.genericControls.formNameValueManipulationModal.lblValueType')}>
                                        <Select value={this.state.valueType} onChange={this.handleValueTypeChange}>
                                            <Option value="string">{t('app.genericControls.formNameValueManipulationModal.lblValueTypeString')}</Option>
                                            <Option value="number">{t('app.genericControls.formNameValueManipulationModal.lblValueTypeNumber')}</Option>
                                            <Option value="boolean">{t('app.genericControls.formNameValueManipulationModal.lblValueTypeBoolean')}</Option>
                                        </Select>
                                    </Form.Item>
                                );
                            } else {
                                return null;
                            }
                        }}
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            switch (this.state.valueType) {
                                case "number":
                                    return (
                                        <Form.Item label={t('app.genericControls.formNameValueManipulationModal.lblAddValue')}
                                            name="value"
                                            rules={[{ required: true, message: t('app.genericControls.formNameValueManipulationModal.msgAddValue') }]}
                                        >
                                            <InputNumber
                                                style={{width: '300px'}}
                                                placeholder={t('app.genericControls.formNameValueManipulationModal.lblAddValue')}
                                            />
                                        </Form.Item>
                                    );
                                case "boolean":
                                    return (
                                        <Form.Item label={t('app.genericControls.formNameValueManipulationModal.lblAddValue')}
                                            name="value"
                                            valuePropName="checked"
                                        >
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    );
                                default:
                                    return (
                                        <Form.Item label={t('app.genericControls.formNameValueManipulationModal.lblAddValue')}
                                            name="value"
                                            rules={[{ required: true, message: t('app.genericControls.formNameValueManipulationModal.msgAddValue') }]}
                                        >
                                            <Input
                                                disabled={this.state.initVals.value && this.props.preventOwerwrite}
                                                placeholder={t('app.genericControls.formNameValueManipulationModal.lblAddValue')}
                                            />
                                        </Form.Item>
                                    );
                            }
                        }}
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            if (this.props.useColor) {
                                return (
                                    <Form.Item label={t('app.genericControls.formNameValueManipulationModal.lblAddColor')}
                                        name="color"
                                    >
                                        {/* <Input
                                            placeholder={t('app.genericControls.formNameValueManipulationModal.lblAddColor')}
                                        /> */}
                                        <FormColorPicker ></FormColorPicker>
                                    </Form.Item>
                                );
                            } else {
                                return null;
                            }
                        }}
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button type="primary" icon={<PlusOutlined />} onClick={this.handleSubmit}
                                disabled={
                                    isUndefined(this.formRef.current) ||
                                    (!isUndefined(this.formRef.current) &&
                                        ((!this.formRef.current.isFieldsTouched(true) && !this.props.isEdit) ||
                                            this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length > 0)
                                    )
                                }
                            >
                                {(this.props.isEdit ? t('app.genericControls.formNameValueManipulationModal.btnUpdateItem') : t('app.genericControls.formNameValueManipulationModal.btnAddItem'))}
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        ];
    }
}

export default withTranslation()(FormNameValueManipulationModal);

FormNameValueManipulationModal.propTypes = {
    onItemAdd: PropTypes.func.isRequired,
    onItemUpdated: PropTypes.func.isRequired,
    onModalHide: PropTypes.func.isRequired,
    itemValues: PropTypes.object,
    isEdit: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    useColor: PropTypes.bool,
    useTypes: PropTypes.bool,
    preventOwerwrite: PropTypes.bool
};